//首页 Landing
export const Landing = {
  Recharge: 'Nạp game ngay!',
  chooseGame: 'Mời chọn game bạn muốn nạp',
  enterGameName: 'Hãy nhập tên game',
  gameList: 'Danh sách game',
  enter: 'Nạp ngay',
  more: 'Xem thêm',
  problem: 'Tại sao nên sử dụng cổng thanh toán Games để nạp game?',
  answer1: '1. HĐ Hạn Giờ: HĐ phong phú đa dạng, ra mắt hạn giờ',
  answer2: '2. Đặc Quyền VIP: Hưởng thụ đặc quyền dành riêng cho VIP, nhận càng nhiều thưởng đạo cụ trong game',
  answer3: '3. CSKH Riêng: Hưởng thụ CSKH VIP riêng biệt, giúp bạn giải quyết muộn phiền',
  answer4: '4. HĐ Ưu Đãi: HĐ Voucher không định kỳ, nhận được càng nhiều coin cùng giá cả càng thêm ưu đãi',
  answer5: '5. Càng nhiều cách nạp: Nhiều cách nạp khác nhau, giúp bạn không còn lo âu khi nạp',
  answer1_title: 'HĐ Hạn Giờ',
  answer2_title: 'Đặc Quyền VIP',
  answer3_title: 'CSKH Riêng',
  answer4_title: 'HĐ Ưu Đãi',
  answer5_title: 'Càng nhiều cách nạp',
  answer1_dec: 'HĐ phong phú đa dạng, ra mắt hạn giờ',
  answer2_dec: 'Hưởng thụ đặc quyền dành riêng cho VIP, nhận càng nhiều thưởng đạo cụ trong game',
  answer3_dec: 'Hưởng thụ CSKH VIP riêng biệt, giúp bạn giải quyết muộn phiền',
  answer4_dec: 'HĐ Voucher không định kỳ, nhận được càng nhiều coin cùng giá cả càng thêm ưu đãi',
  answer5_dec: 'Nhiều cách nạp khác nhau, giúp bạn không còn lo âu khi nạp',
  close: 'Thu gọn',
  allGame: 'Tất cả game'
}

//底部信息 Footer
export const Footer = {
  mgolFooter: 'Giấy phép cung cấp dịch vụ trò chơi điện tử G1 trên mạng số: 283/GP-BTTTT ngày 07 tháng 08 năm 2023',
  mocFooter: 'Giấy phép cung cấp dịch vụ trò chơi điện tử G1 trên mạng số: 463/GP-BTTTT ngày 28 tháng 10 năm 2019',
  address: 'Địa chỉ:'
}

//导航栏信息 info
export const Info = {
  return: 'Quay về trang chủ',
  store: 'Tiệm Điểm',
  order: 'Đơn hàng',
  unlock: 'Mở khoá',
  exit: 'Thoát',
  cancel: 'Hủy',
  napProblem:'Lỗi nạp'
}

//登录页 Login
export const Login = {
  gameId: 'Đăng nhập tài khoản',
  or: 'Hoặc sử dụng',
  regAccount: 'Đăng ký tài khoản',
  emailAccount: 'Tài khoản Email',
  phoneAccount: 'Tài khoản Phone',
  password: 'Mật khẩu',
  confirm: 'Xác nhận',
  enterEmail: 'Hãy nhập email',
  enterCode: 'Hãy nhập mã xác thực',
  enterPassword: 'Hãy nhập mật khẩu',
  enterPasswordAgain: 'Hãy nhập lại mật khẩu',
  retrunLogin: 'Trở về đăng nhập',
  enterTrueEmail: 'Hãy nhập tài khoản email chính xác!',
  enterNumPassword: 'Hãy nhập mật khẩu 6-20 ký tự bao gồm chữ cái và số',
  differentPassword: 'Hai lần nhập mật khẩu không giống nhau!',
  sendCode: 'Gửi tin nhắn',
  enterAccount: 'Vui lòng nhập tài khoản message',
  enterTrueEmail: 'Hãy nhập email chính xác',
  enterTruePhone: 'Hãy nhập SĐT chính xác',
  loginSuccess: 'Liên kết thành công',
  resend: 'Gửi lại',
  reg: 'Đăng ký',
}


//详情页 consume
export const consume = {
  noData: 'Không có dữ liệu',
  gameId: 'Chọn nhân vật',
  chooseServer: 'Chọn máy chủ',
  chooseRole: 'Chọn nhân vật',
  Server: 'Máy chủ:',
  roleId: 'ID nhân vật:',
  roleName: 'Nhân vật:',
  chooseOtherRole: 'Chọn nhân vật khác',
  ChooseCombo: 'Chọn vật phẩm',
  returen: 'hoàn trả ：',
  choosePayMethod: 'Chọn phương thức thanh toán',
  remaining: 'Số dư',
  extraGift: 'Tặng thêm',
  next: 'Tiếp theo',
  confirmInfo: 'Xác nhận thông tin',
  pay: 'Thanh toán',
  selectCoupon: 'Chọn Coupon',
  canUseCoupon: '{num} Coupon',
  notUse: 'Không',
  price: 'giá bán',
  btnRemark: 'Bằng việc nhấn vào nút Thanh toán ngay, Bạn đồng ý rằng, giao dịch này là không hoàn, không hủy.',
  limitedTimeEvent: '“Bạn còn {num} lần tham gia miễn phí đơn hạn giờ (Coin cao nhất có thể phải nạp), dùng voucher không thể tham gia hoạt động”',
  currency: 'Xu Game',
  special: 'Đặc biệt',
  enterAccount: 'Vui lòng nhập tài khoản message',
  enterPassword: 'Vui lòng nhập mật khẩu',
  step1: 'Bước 1/4: Tài khoản Game ID',
  step2: 'Bước 2/4:Chọn gói',
  step3: 'Bước 3/4:Chọn phương thức thanh toán',
  step4: 'Bước 4/4:Xác nhận thông tin',
  step2_1: 'Bước 1/3：Chọn một quốc gia',
  step3_1: 'Bước 2/3:Chọn phương thức thanh toán',
  step4_1: 'Bước 3/3:Xác nhận thông tin',
  insufficientBalance: 'Số dư không đủ',
  MinDeposit: 'Mốc thanh toán thấp nhất {money}',
  validityPeriod: 'Thời gian hiệu lực  ',
  availableDates: 'Thời gian có sẵn',
  deliver1: 'Đủ {money} USD Tặng {gift} Coin',
  deliver2: 'Đủ {money} USD Giảm {gift}%',
  deliver3: 'Đủ {money} USD Giảm {gift}',
  paySuccess: 'Thanh toán thành công',
  revise: 'Đổi',
  enterCard: 'Nhập mã thẻ',
  cardRecharge: 'Nạp thẻ',
  channelRebate: 'Kênh hoàn：',
  VIPRebate: 'VIP hoàn：',
  couponRebate: 'Voucher hoàn：',
  totalRebate: 'Tổng số tiền hoàn：',
  remainingNum: '{num} lần còn lại',
  limitNum: 'Giới hạn dùng hôm nay {num} lần',
  notUseCoupon: 'Không có voucher có thể sử dụng',
  reselect: 'Voucher hiện tại không dùng được với phương thức thanh toán này, Hãy chọn lại!',
  channel: 'Kênh thanh toán：',
  use:"Đang Dùng",
  chooseCountry:'Chọn một quốc gia',
  payCountry:'Khu vực thanh toán',
  napRemark:'Giao dịch đang xử lý, nếu gặp bất cứ vấn đề gì xin hãy phản hồi cho chúng tôi qua phần "Lỗi nạp"',
  autoLogin:'Mua Voucher',
  maintain:'Đang bảo trì, xin chọn phương thức thanh toán khác',
  notice:"Giới Thiệu Webpay"
}

//AtmResult 和 purchaseResult
export const result = {
  paySuccess: 'Thanh toán thành công',
  payFail: 'Thanh toán thất bại',
  orderNo: 'Mã đơn',
  payMoney: 'Số tiền thanh toán',
  giftAmount: 'Miễn phí đơn hạn giờ',
  return: 'Quay lại'
}

//首页头 page
export const page = {
  enterGameName: 'Hãy nhập tên game',
}

//gate
export const gate = {
  serialNumber: 'Số serial:',
  enterSerialNumber: 'Nhập serial thẻ',
  cardNumber: 'Mã thẻ:',
  enterCardNumber: 'Nhập mã thẻ',
  topUp: 'Nạp thẻ',
  topUpSuccess: 'Nạp thành công',
  sort:'Loại:',
}

//unlock
export const unlock = {
  confirm: 'Nạp thẻ',
}

//store
export const store = {
  pointsStore: 'Tiệm Điểm',
  exchangeRecord: 'Nhật ký đổi',
  integral: 'Điểm',
  productTypes: 'Loại vật phẩm',
  RemainingNum: 'Còn dư',
  Revise: 'Đổi',
  NotEnoughPoints: 'Điểm không đủ',
  exchangeLimit: 'Đổi hôm nay đã đạt giới hạn',
  NotEnoughGoods: 'Vật phẩm hôm nay không đủ',
  productExchangeLimit: 'Vật phẩm đạt giới hạn đổi',
}

//log
export const log = {
  exchangeLog: 'Nhật ký đổi',
  sellingPrice: 'giá bán',
  giftCode: 'Code quà',
  status: 'Trạng thái',
  shippingTime: 'Thời gian giao hàng',
  DataCompleted: 'Đã tải tất cả dữ liệu',
  unpaid: 'Chưa thanh toán',
  WaitDelivery: 'Chờ gửi hàng',
  shipped: 'Đã gửi',
}

//goodsDetail
export const goodsDetail = {
  gift: 'Quà tặng',
  sellingPrice: 'giá bán',
  RemainingNum: 'Còn dư',
}

//goodsPay
export const goodsPay = {
  game: 'Trò chơi',
  receiver: 'Người nhận',
  enterReceiver: 'Hãy nhập họ tên người nhận',
  phone: 'Điện thoại',
  enterPhone: 'Nhập số điện thoại',
  address: 'Địa chỉ:',
  enterAddress: 'Nhập địa chỉ',
  payType: 'Phương thức thanh toán',
  confirmProduct: 'Có đổi vật phẩm này?',
  cancel: 'Hủy',
  confirm: 'Đổi',
  enterCompleteInfo: 'Hãy nhập nội dung hoàn chỉnh',
  chooseProduce: 'Hãy chọn mục',
}

//giftCode
export const giftCode = {
  confirm: 'Đổi',
  enterCard: 'Nhập mã thẻ',
  enterCompleteInfo: 'Hãy nhập nội dung hoàn chỉnh',
}

//coin
export const coin = {
  walletBalance: 'Tiền trong ví:',
  coinBalance: 'Coin khoá:',
  topUp: 'Nạp thẻ',
}

//orderList
export const orderList = {
  purchaseLog: 'Nhật ký đổi',
  purchasePrice: 'Số tiền',
  giftAmount: 'Miễn phí đơn hạn giờ',
  createTime: 'Thời gian tạo',
}

//codeBind
export const codeBind = {
  beta: 'Tư cách Alpha Test',
  code: 'Mã xác thực',
  enterCode: 'Hãy nhập mã xác thực',
  activation: 'Kích hoạt',
}

//VipList
export const VipList = {
  need: 'Còn cách cấp sau：{exp} EXP',
  superlative: 'Đã đạt cấp này',
  exclusivePrivileges: 'Đặc quyền riêng',
  growthLevel:'Cấp độ trưởng thành',
  dec1:'Mỗi lần nạp, Sẽ tính exp theo tỉ lệ 0.01USD = 1 EXP, Sẽ tính trực tiếp vào tài khoản.Dựa theo danh sách trên, Khi đạt đủ điểm kinh nghiệm sẽ nhận cấp VIP tương ứng, Người chơi sẽ được hưởng quyển lợi trên cấp độ VIP hiện tại đang có.',
  dec2:'Đồng thời, Cứ mỗi nữa năm, tài khoản không đạt mốc yêu cầu tích luỹ để duy trì cấp sẽ trừ tương ứng số exp, có thể sẽ trong trường hợp giảm cấp VIP, cụ thể EXP giảm như sau:',
  table1:'Cấp VIP',
  table2:'Trừ EXP/nữa năm',
  table3:'Tích nạp giữ cấp/nữa năm',
  receive:'Nhận',
  time:'lần',
  name1:'Báo danh',
  name2:'Quà VIP',
  name3:'Ưu đãi VIP',
  name4:'Liên kết',
  name5:'Thưởng nạp',
  name6:'Tìm lại đã mất',
  name7:'Xoá liên kết',
  name8:'Gặp phúc lợi',
  name9:'khôi phục thao tác',
}

//sign in 签到积分
export const signIn = {
  title:'Điểm báo danh',
  dec:'Người chơi từ báo danh, Nhận điểm tương ứng,cấp VIP khác nhau có thể nhận thêm điểm, Dùng điểm đổi thưởng tương ứng, Điểm thưởng cụ thể như sau:',
  table1:'Cấp VIP',
  table2:'Điểm thưởng từ báo danh',
}

//vipGift VIP礼包
export const vipGift = {
  vipGift: 'Quà VIP',
  chooseServer: 'Chọn máy chủ',
  chooseRole: 'Chọn nhân vật',
  receivingGifts:'Nhận Quà',
  gift: 'Quà',
  confirmUse:'Xác định tiêu hao :',
  usePrivileges:'Dùng lễ bao đặc quyền VIP cho nhân vật này ?',
  chooseServerAndRole: 'Hãy chọn nhân vật và server',
  dec1:'Người chơi chỉ nhận quyền lợi gói quà mỗi tháng 1 lần, người chơi có cấp VIP khác nhau có thể nhận gói quà VIP khác nhau, gói quà này nhận thủ công, sau khi nhận có thể gửi bằng một trong những cách sau:',
  dec2:'1. Tự động gửi vào danh sách gói quà của tôi, người chơi có thể sao chép code quà, đổi thưởng giá trị tương ứng trong game tương ứng.',
  dec3:'2. Trực tiếp gửi đến nhân vật nhận tương ứng thông qua game.',
  table1:'Cấp VIP',
  table2:'Quà VIP',
  tableText:'Quà Đặc Quyền',
}

//vipCoupon VIP券
export const vipCoupon = {
  vipCoupon: 'Voucher VIP',
  dec1:'Khi thăng cấp độ VIP, Hệ thống sẽ tự động gửi  voucher đến tài khoản, Voucher VIP như nhau:',
  table1:'Cấp VIP',
  table2:'Voucher VIP',
  tableText2:'Quà voucher nạp đủ tặng thường',
  tableText3:'Quà voucher nạp đủ tặng cao cấp',
  tableText4:'Quà voucher nạp đủ giảm đặc biệt',
  tableText5:'Lễ bao ưu đãi chí tôn',
  dec2:'Quà voucher nạp đủ tặng thường：',
  dec3:'Quà voucher nạp đủ tặng cao cấp：',
  dec4:'Quà voucher nạp đủ tặng đặc biệt：',
  dec5:'Lễ bao ưu đãi chí tôn：',
  dec2Text:'1 usd Tặng 10coin*1，2 usd Tặng 25coin*1',
  dec3Text:'5 usd Tặng 75coin*1，10 usd Tặng 180coin*1',
  dec4Text:'20 usd Tặng 400coin*1，50 usd Tặng 1100coin*1',
  dec5Text:'100 usd Tặng 3000coin*1，300 usd Tặng 10000coin*1',
}


//bind 绑定
export const bind = {
  enterEmail: 'Hãy nhập email',
  enterCode: 'Hãy nhập mã xác thực',
  enterPassword: 'Hãy nhập mật khẩu',
  enterPasswordAgain: 'Hãy nhập lại mật khẩu',
  enterTrueEmail: 'Hãy nhập tài khoản email chính xác!',
  enterNumPassword: 'Hãy nhập mật khẩu 6-20 ký tự bao gồm chữ cái và số',
  differentPassword: 'Hai lần nhập mật khẩu không giống nhau!',
  sendCode: 'Gửi tin nhắn',
  bindSuccess: 'Liên kết thành công',
}

//nap 充值奖励
export const nap = {
  title:'Nạp hoàn tiền ví',
  dec1:'Nhận Coin với mốc nạp tương ứng theo tỷ lệ phần trăm',
  dec2:'Ví dụ: Nếu mua 200 Voucher, trị giá 2 USD, thì công thức hoàn lại coin là: 2*100*1%=2 Coin (Hoàn trả cụ thể của các cấp VIP như bảng dưới đây)',
  table1:'Cấp VIP',
  table2:'Thưởng thêm',
  tableText:'Thưởng {num}% giá trị đã nạp',
  tableText2:'Thưởng 1% giá trị đã nạp',
  tableText3:'Thưởng 2% giá trị đã nạp',
  tableText4:'Thưởng 3% giá trị đã nạp',
}

//retrieve 被盗找回
export const retrieve = {
  title:'Tìm lại đã mất',
  dec1:'Khi cấp VIP đạt cấp 6 trở lên, Sẽ nhận đặc quyền tìm lại quyền lợi đã mất, cụ thể theo cấp VIP như sau:',
  table1:'Cấp VIP',
  table2:'Tìm lại số lần',
  tableText1:'1 lần / nữa năm',
}

//unbind
export const unbind = {
  unbind: 'Xoá liên kết',
  account:'Tài khoản',
  source:'Nguồn',
  operate:'Thao tác',
  sendCode: 'Gửi tin nhắn',
  unbindAccount: 'Thao tác này sẽ hủy liên kết tài khoản, có tiếp tục không?',
  cancelUnbind: 'Hủy liên kết',
  unbindSuccess: 'Hủy liên kết thành công!',
  cancelUnbindSuccess: 'Đã xóa hủy liên kết',
}

//meet 遇见福利
export const meet = {
  title:'Gặp phúc lợi',
  dec1:'Người chơi đăng ký tài khoản nền tảng, chính là ngày gặp gỡ nền tảng, Khi cấp VIP đạt cấp 7 trở lên, Mỗi năm ngày hôm nay hệ thống sẽ tặng phúc lợi vào tài khoản, Phúc lợi theo cấp VIP như sau:',
  time:'Thời gian gặp :',
  table1:'Cấp VIP',
  table2:'Lễ bao phúc lợi',
  tableText:'Vé ưu đãi trị gá {num} VND'
}

//recovery 误操作恢复
export const recovery = {
  title:'khôi phục thao tác',
  dec1:'Khi cấp VIP đạt cấp 7 trở lên, Sẽ nhận đặc quyền khôi phục đặc quyền từ thao tác nhầm, cụ thể theo cấp VIP như sau:',
  table1:'Cấp VIP',
  table2:'Khôi phục số lần',
  tableText:'lần / nữa năm'
}



