//首页 Landing
export const Landing = {
  Recharge: 'เติมเงินทันที',
  chooseGame: 'โปรดเลือกเกมที่ท่านต้องการเติมเงิน',
  enterGameName: 'โปรดกรอกชื่อเกม',
  gameList: 'รายการเกม',
  enter: 'เข้าทันที',
  more: 'ดูเพิ่มเติม',
  problem: 'ทำไมต้องใช้ช่องทางชำระเงินในเกมเพื่อเติมเงิน?',
  answer1: '1. กิจกรรมจำกัดเวลา: กิจกรรมหลากหลายในเวลาที่จำกัด',
  answer2: '2. สิทธิพิเศษ VIP: เพลิดเพลินกับสิทธิพิเศษ VIP เฉพาะ ได้รับรางวัลไอเทมในเกมมากขึ้น',
  answer3: '3. การบริการลูกค้าเฉพาะ: เพลิดเพลินกับการบริการลูกค้า VIP เฉพาะ เพื่อแก้ไขปัญหาของท่าน',
  answer4: '4. กิจกรรมลดราคา: กิจกรรมตั๋วลดราคาเป็นครั้งคราว ได้รับเหรียญทองแพลตฟอร์มที่มากขึ้นหรือซื้อไอเทมในเกมแบบลดราคา',
  answer5: '5. วิธีเติมเงินที่มากขึ้น: วิธีเติมเงินหลายรูปแบบ เพื่อให้ท่านเติมเงินได้อย่างไร้กังวล',
  answer1_title: 'กิจกรรมจำกัดเวลา',
  answer2_title: 'สิทธิพิเศษ VIP',
  answer3_title: 'การบริการลูกค้าเฉพาะ',
  answer4_title: 'กิจกรรมลดราคา',
  answer5_title: 'วิธีเติมเงินที่มากขึ้น',
  answer1_dec: 'กิจกรรมหลากหลายในเวลาที่จำกัด',
  answer2_dec: 'เพลิดเพลินกับสิทธิพิเศษ VIP เฉพาะ ได้รับรางวัลไอเทมในเกมมากขึ้น',
  answer3_dec: 'เพลิดเพลินกับการบริการลูกค้า VIP เฉพาะ เพื่อแก้ไขปัญหาของท่าน',
  answer4_dec: 'กิจกรรมตั๋วลดราคาเป็นครั้งคราว ได้รับเหรียญทองแพลตฟอร์มที่มากขึ้นหรือซื้อไอเทมในเกมแบบลดราคา',
  answer5_dec: 'วิธีเติมเงินหลายรูปแบบ เพื่อให้ท่านเติมเงินได้อย่างไร้กังวล',
  close: 'ปิด',
  allGame: 'เกมทั้งหมด'
}

//底部信息 Footer
export const Footer = {
  mgolFooter: 'ระบุหมายเลขใบอนุญาตสำหรับบริการเกมออนไลน์ G1: 283/GP-BTTTT วันที่: 7 สิงหาคม 2566',
  mocFooter: 'ระบุหมายเลขใบอนุญาตสำหรับบริการเกมออนไลน์ G1: 463/GP-BTTTT วันที่: 28 ตุลาคม 2562',
  address: 'ที่อยู่:'
}

//导航栏信息 info
export const Info = {
  return: 'กลับหน้าแรก',
  store: 'ร้านคะแนน',
  order: 'ใบสั่งซื้อ',
  unlock: 'ปลดล็อก',
  exit: 'ออก',
  cancel: 'ยกเลิก',
  napProblem:'คำถามการเติมเงิน'
}

//登录页 Login
export const Login = {
  gameId: 'ล็อกอินบัญชี',
  or: 'หรือใช้',
  regAccount: 'ลงทะเบียนบัญชี',
  emailAccount: 'บัญชี Email',
  phoneAccount: 'บัญชี phone',
  password: 'รหัสผ่าน',
  confirm: 'ยืนยัน',
  enterEmail: 'โปรดกรอกอีเมลของท่าน',
  enterCode: 'โปรดกรอกรหัสยืนยัน',
  enterPassword: 'โปรดกรอกรหัสของท่าน',
  enterPasswordAgain: 'โปรดกรอกรหัสของท่านใหม่อีกครั้ง',
  retrunLogin: 'กลับหน้าล็อกอิน',
  enterTrueEmail: 'โปรดกรอกบัญชีอีเมลให้ถูกต้อง!',
  enterNumPassword: 'โปรดกรอกรหัสผ่าน 6-20 ตัวอักษร โดยประกอบด้วยตัวอักษรและตัวเลข',
  differentPassword: 'กรอกรหัสผ่านสองครั้งไม่เหมือนกัน!',
  sendCode: 'ส่งรหัสยืนยัน',
  enterAccount: 'โปรดกรอกข้อมูลบัญชีของท่าน',
  enterTrueEmail: 'โปรดกรอกอีเมลให้ถูกต้อง',
  enterTruePhone: 'โปรดกรอกเบอร์มือถือให้ถูกต้อง',
  loginSuccess: 'ล็อกอินสำเร็จ',
  resend: 'ส่งอีกครั้ง',
  reg: 'ลงทะเบียน',
}


//详情页 consume
export const consume = {
  noData: 'ไม่มีข้อมูล',
  gameId: 'เลือกตัวละคร',
  chooseServer: 'เลือกเซิร์ฟเวอร์',
  chooseRole: 'เลือกตัวละคร',
  Server: 'เซิร์ฟเวอร์:',
  roleId: 'ID ตัวละคร:',
  roleName: 'ชื่อตัวละคร:',
  chooseOtherRole: 'เลือกตัวละครอื่น',
  ChooseCombo: 'เลือกสินค้า',
  returen: 'กลับ:',
  choosePayMethod: 'เลือกวิธีชำระเงิน',
  remaining: 'เหลือ',
  extraGift: 'มอบเพิ่มเติม',
  next: 'ถัดไป',
  confirmInfo: 'ยืนยันข้อมูล',
  pay: 'ชำระ',
  selectCoupon: 'เลือกตั๋วส่วนลด',
  canUseCoupon: ' {num} ตั๋วส่วนลด',
  notUse: 'ไม่มี',
  price: 'ราคา',
  btnRemark: 'กดปุ่ม "ชำระทันที" จะแสดงว่าท่านยอมรับการทำธุรกรรมนี้และไม่สามารถคืนเงินหรือยกเลิกได้',
  limitedTimeEvent: ' "จำกัดเวลาเข้าร่วมฟรี {num}  ครั้ง (เหรียญสูงสุดที่สามารถเติมเงินได้) ใช้ตั๋วลดราคาไม่สามารถเข้าร่วมกิจกรรมได้" ',
  currency: 'สกุลเงิน',
  special: 'พิเศษ',
  enterAccount: 'โปรดกรอกข้อมูลบัญชีของท่าน',
  enterPassword: 'โปรดกรอกรหัสผ่าน',
  step1: 'ขั้นที่ 1/4: บัญชี ID เกม',
  step2: 'ขั้นที่ 2/4: เลือกเซ็ต',
  step3: 'ขั้นที่ 3/4: เลือกวิธีชำระเงิน',
  step4: 'ขั้นที่ 4/4: ยืนยันข้อมูล',
  step2_1: 'ขั้นที่ 1/3：เลือกประเทศ',
  step3_1: 'ขั้นที่ 2/3: เลือกวิธีชำระเงิน',
  step4_1: 'ขั้นที่ 3/3: ยืนยันข้อมูล',
  insufficientBalance: 'จำนวนเงินไม่พอ',
  MinDeposit: 'จำนวนเงินชำระขั้นต่ำ {money}',
  validityPeriod: 'ระยะเวลามีผล',
  availableDates: 'วันที่สามารถใช้ได้',
  deliver1: 'ครบ {money} USD แจก {gift} Coin',
  deliver2: 'ครบ {money} USD ลด {gift}%',
  deliver3: 'ครบ {money} USD ลด {gift}',
  paySuccess: 'ชำระสำเร็จ',
  revise: 'แก้ไข',
  enterCard: 'กรอกรหัสบัตร',
  cardRecharge: 'เติมเงินบัตร',
  channelRebate: 'ช่องทางโบนัส:',
  VIPRebate: 'โบนัส VIP:',
  couponRebate: 'โบนัสตั๋วลดราคา:',
  totalRebate: 'จำนวนเงินโบนัสรวม:',
  remainingNum: 'เหลือ {num} ครั้ง',
  limitNum: 'จำกัดการใช้วันนี้ {num} ครั้ง',
  notUseCoupon: 'ไม่มีตั๋วลดราคาที่สามารถใช้ได้',
  reselect: 'ตั๋วลดราคาไม่สามารถใช้กับวิธีชำระนี้ได้ในปัจจุบัน โปรดเลือกใหม่อีกครั้ง!',
  channel: 'ช่องทางการชำระ:',
  use: "ใช้",
  chooseCountry:'เลือกประเทศ',
  payCountry:'ภูมิภาคที่ชำระเงิน',
  napRemark:'ในขั้นตอนการเติมเงิน หากเจอปัญหาใด ๆ คุณสามารถให้คำติชมผ่านปุ่ม "คำถามการเติมเงิน" ที่ด้านบนได้ครับ.',
  autoLogin:'ซื้อ ตั๋วกำนัล',
  maintain:'กำลังปรับปรุง โปรดใช้วิธีเติมเงินอื่น',
  notice:"Giới Thiệu Webpay"
}

//AtmResult 和 purchaseResult
export const result = {
  paySuccess: 'ชำระสำเร็จ',
  payFail: 'ชำระล้มเหลว',
  orderNo: 'หมายเลขใบสั่งซื้อ',
  payMoney: 'จำนวนเงินที่ชำระ',
  giftAmount: 'จำนวนเงินที่มอบให้',
  return: 'กลับ'
}

//首页头 page
export const page = {
  enterGameName: 'โปรดกรอกชื่อเกม',
}

//gate
export const gate = {
  serialNumber: 'หมายเลขซีเรียล:',
  enterSerialNumber: 'กรอกหมายเลขซีเรียลบัตร:',
  cardNumber: 'หมายเลขบัตร',
  enterCardNumber: 'กรอกหมายเลขบัตร',
  topUp: 'เติมเงินบัตร',
  topUpSuccess: 'เติมเงินสำเร็จ',
  sort:'พิมพ์:',
}

//unlock
export const unlock = {
  confirm: 'ยืนยัน',
}

//store
export const store = {
  pointsStore: 'ร้านคะแนน',
  exchangeRecord: 'บันทึกแลกเปลี่ยน',
  integral: 'คะแนน',
  productTypes: 'ประเภทสินค้า',
  RemainingNum: 'คงเหลือ',
  Revise: 'แก้ไข',
  NotEnoughPoints: 'คะแนนไม่พอ',
  exchangeLimit: 'การแลกเปลี่ยนวันนี้ถึงลิมิตแล้ว',
  NotEnoughGoods: 'สินค้าวันนี้ไม่พอ',
  productExchangeLimit: 'สินค้านี้ถึงลิมิตการแลกแล้ว',
}

//log
export const log = {
  exchangeLog: 'บันทึกแลกเปลี่ยน',
  sellingPrice: 'ราคาขาย',
  giftCode: 'รหัสแพ็ก',
  status: 'สถานะ',
  shippingTime: 'เวลาจัดส่ง',
  DataCompleted: 'โหลดข้อมูลทั้งหมดแล้ว',
  unpaid: 'ยังไม่ได้ชำระ',
  WaitDelivery: 'รอจัดส่ง',
  shipped: 'จัดส่งแล้ว',
}

//goodsDetail
export const goodsDetail = {
  gift: 'ของขวัญ',
  sellingPrice: 'ราคาขาย',
  RemainingNum: 'คงเหลือ',
}

//goodsPay
export const goodsPay = {
  game: 'เกม',
  receiver: 'ผู้รับ',
  enterReceiver: 'โปรดกรอกชื่อเต็มผู้รับ',
  phone: 'มือถือ',
  enterPhone: 'โปรดกรอกเบอร์มือถือของท่าน',
  address: 'ที่อยู่',
  enterAddress: 'โปรดกรอกที่อยู่ของท่าน',
  payType: 'วิธีชำระ',
  confirmProduct: 'ต้องการแลกสินค้านี้หรือไม่?',
  cancel: 'ยกเลิก',
  confirm: 'ยืนยัน',
  enterCompleteInfo: 'โปรดกรอกข้อมูลเนื้อหาให้ครบ',
  chooseProduce: 'โปรดเลือกสินค้า',
}

//giftCode
export const giftCode = {
  confirm: 'ยืนยัน',
  enterCard: 'โปรดกรอกหมายเลขบัตร',
  enterCompleteInfo: 'โปรดกรอกข้อมูลเนื้อหาให้ครบ',
}

//coin
export const coin = {
  walletBalance: 'จำนวนเงินในกระเป๋า',
  coinBalance: 'จำนวนเงิน Coin:',
  topUp: 'เติมเงินบัตร',
}

//orderList
export const orderList = {
  purchaseLog: 'บันทึกการซื้อ',
  purchasePrice: 'จำนวนเงินการซื้อ',
  giftAmount: 'จำนวนเงินที่มอบให้',
  createTime: 'เวลาสร้าง',
}

//codeBind
export const codeBind = {
  beta: 'คุณสมบัติเบต้า',
  code: 'รหัสยืนยัน',
  enterCode: 'โปรดกรอกรหัสยืนยัน',
  activation: 'เปิดใช้',
}

//VipList
export const VipList = {
  need: 'ต้องการอัปเกรดเป็นเลเวลถัดไป:{exp} EXP',
  superlative: 'ถึงเลเวลสูงสุดแล้ว',
  exclusivePrivileges: 'สิทธิพิเศษเฉพาะ',
  growthLevel: 'เลเวลเติบโต',
  dec1: 'หลังจากทุกครั้งที่ผู้เล่นเติมเงิน EXP ที่สอดคล้องจะถูกแจกไปยังบัญชีผู้เล่นในรูปแบบ 0.01USD=1EXP ตามตารางข้างต้น หลังจากได้รับ EXP ที่สอดคล้องแล้วก็จะสามารถถึงเลเวล VIP ที่สอดคล้องด้วย ขณะเดียวกันผู้เล่นสามารถรับสิทธิสมาชิกเลเวล VIP ทั้งหมดในปัจจุบันได้',
  dec2: 'ในขณะเดียวกัน โดยนับ 1 รอบต่อครึ่งปี การเติมเงินสะสมจะไม่สามารถบรรลุบัญชี EXP เพื่อประกันเลเวลได้ ทั้ง EXP ที่เกี่ยวข้องก็จะถูกหักออกด้วยมีหลายกรณีที่เลเวล VIP อาจถูกลดระดับลง สถานะ EXP ที่เห็นได้ชัดเจนแสดงอยู่ในตารางด้านล่าง:',
  table1: 'เลเวล VIP',
  table2: 'หัก EXP/ครึ่งปี',
  table3: 'สะสมประกันเลเวล/ครึ่งปี',
  receive: 'รับ',
  time: 'ครั้ง',
  name1: 'คะแนนเช็คอิน',
  name2: 'แพ็ก VIP',
  name3: 'ตั๋วVIP',
  name4: 'ผูก',
  name5: 'รางวัลเติมเงิน',
  name6: 'กู้คืนจากขโมย',
  name7: 'ปลด',
  name8: 'สวัสดิการพบปะ',
  name9: 'ฟื้นฟูข้อผิดพลาด',
}

//sign in 签到积分
export const signIn = {
  title: 'คะแนนเช็คอิน',
  dec: 'หลังจากผู้เล่นเช็คอินแพลตฟอร์ม จะสามารถได้รับรางวัลคะแนนที่เกี่ยวข้อง เลเวล VIP ที่ต่างกันจะได้รับรางวัลคะแนนที่ต่างกัน ซึ่งสามารถแลกเปลี่ยนของขวัญที่เกี่ยวข้องผ่านคะแนนได้ คะแนนรางวัลมีดังนี้:',
  table1: 'เลเวล VIP',
  table2: 'คะแนนรางวัลเช็คอิน',
}

//vipGift
export const vipGift = {
  vipGift: 'ของขวัญ Vip',
  chooseServer: 'เลือกเซิร์ฟเวอร์',
  chooseRole: 'เลือกตัวละคร',
  receivingGifts: 'รับของขวัญ',
  gift: 'ของขวัญ',
  confirmUse: 'ยืนยันการใช้:',
  usePrivileges: 'ใช้แพ็กสิทธิพิเศษ vip กับตัวละครนี้หรือไม่?',
  chooseServerAndRole: 'โปรดเลือกตัวละครและเซิร์ฟเวอร์',
  dec1: 'ผู้เล่นสามารถรับสิทธิแพ็กได้ 1 ครั้งต่อเดือน ผู้เล่นที่เลเวล VIP ต่างกันสามารถรับแพ็ก VIP ที่ต่างกัน แพ็กนี้สามารถรับได้ด้วยตัวเอง หลังจากรับแล้วจะแจกในรูปแบบใดรูปแบบหนึ่งต่อไปนี้:',
  dec2: '1: แจกอัตโนมัติไปยังตารางแพ็กของฉัน ผู้เล่นสามารถคัดลอกรหัสแพ็ก เพื่อแลกรางวัลมูลค่าเท่ากันที่สอดคล้องในเกมได้',
  dec3: '2: แจกผ่านในเกมโดยตรงไปยังตัวละครที่รับที่เกี่ยวข้อง',
  table1: 'เลเวล VIP',
  table2: 'แพ็ก VIP',
  tableText: 'สิทธิพิเศษแพ็ก',
}

//vipCoupon VIP券
export const vipCoupon = {
  vipCoupon: 'ตั๋วลดราคา VIP',
  dec1: 'เมื่ออัปเกรดเลเวล VIP แต่ละครั้ง ระบบจะแจกตั๋วลดราคาเติมเงินที่เกี่ยวข้องไปยังบัญชีผู้เล่นโดยอัตโนมัติ ตั๋วลดราคาที่สอดคล้องกับเลเวล VIP มีดังนี้:',
  table1: 'เลเวล VIP',
  table2: 'ตั๋วลดราคา VIP',
  tableText1: 'ไม่มี',
  tableText2: 'แพ็กตั๋วส่วนลดฟรีทั่วไป',
  tableText3: 'แพ็กตั๋วส่วนลดฟรีระดับสูง',
  tableText4: 'แพ็กตั๋วส่วนลดฟรีระดับพิเศษ',
  tableText5: 'แพ็กตั๋วส่วนลดฟรีสูงศักดิ์',
  dec2: 'แพ็กตั๋วส่วนลดฟรีทั่วไป:',
  dec3: 'แพ็กตั๋วส่วนลดฟรีระดับสูง:',
  dec4: 'แพ็กตั๋วส่วนลดฟรีระดับพิเศษ:',
  dec5: 'แพ็กตั๋วส่วนลดฟรีสูงศักดิ์:',
  dec2Text: '1 usd แจก 10coin*1，2 usd แจก 25coin*1',
  dec3Text: '5 usd แจก 75coin*1，10 usd แจก 180coin*1',
  dec4Text: '20 usd แจก 400*1，50 usd แจก 1100coin*1',
  dec5Text: '100 usd แจก 3000*1，300 usd แจก 10000coin*1',
}

//bind 绑定
export const bind = {
  enterEmail: 'โปรดกรอกอีเมลของท่าน',
  enterCode: 'โปรดกรอกรหัสยืนยัน',
  enterPassword: 'โปรดกรอกรหัสผ่านของท่าน',
  enterPasswordAgain: 'โปรดกรอกรหัสผ่านของท่านอีกครั้ง',
  enterTrueEmail: 'โปรดกรอกบัญชีอีเมลให้ถูกต้อง!',
  enterNumPassword: 'โปรดกรอกรหัสผ่าน 6-20 ตัวอักษร โดยประกอบด้วยตัวอักษรและตัวเลข',
  differentPassword: 'กรอกรหัสผ่านสองครั้งไม่เหมือนกัน!',
  sendCode: 'ส่งรหัสยืนยัน',
  bindSuccess: 'ผูกสำเร็จ',
}

//nap 充值奖励
export const nap = {
  title: 'รางวัลเติมเงิน',
  dec1: 'ได้รับเปอร์เซ็นต์ระดับเติม Coin ที่เหมาะสมแล้วทำการคืนโบนัส',
  dec2:'ตัวอย่าง: ซื้อตั๋วแทนเงิน 200 ใบ ราคา 2 usd จะคืนในสูตร: 2*100*1%=2 coin (เลเวล VIP ต่างๆคืนโบนัสตามรายการด้านล่าง)',
  table1: 'เลเวล VIP',
  table2: 'รางวัลเพิ่มเติม',
  tableText: 'จำนวนเงินที่ชำระจริง รางวัล {num}%',
  tableText1: 'ไม่มี',
  tableText2: 'จำนวนเงินที่ชำระจริง รางวัล 1%',
  tableText3: 'จำนวนเงินที่ชำระจริง รางวัล 2%',
  tableText4: 'จำนวนเงินที่ชำระจริง รางวัล 3%',
}

//retrieve 被盗找回
export const retrieve = {
  title: 'กู้คืนจากขโมย',
  dec1: 'เมื่อเลเวล VIP บัญชี MGOL ของผู้เล่นถึง V6 ขึ้นไป จะได้สิทธิสมาชิกในการกู้คืนจากขโมย สิทธิกู้คืนจากขโมยที่สอดคล้องของเลเวล VIP มีดังนี้:',
  table1: 'เลเวล VIP',
  table2: 'จำนวนครั้งกู้คืน',
  tableText1: '1 ครั้ง/ครึ่งปี',
}

//unbind
export const unbind = {
  unbind: 'เลิกผูก',
  sendCode: 'ส่งรหัสยืนยัน',
  account: 'บัญชี',
  source: 'แหล่งที่มา',
  operate: 'ดำเนินการ',
  unbindAccount: 'การดำเนินการนี้จะเลิกผูกบัญชีของท่าน ต้องการทำต่อหรือไม่?',
  cancelUnbind: 'ยกเลิกการเลิกผูก',
  unbindSuccess: 'เลิกผูกสำเร็จ',
  cancelUnbindSuccess: 'ยกเลิกการเลิกผูกแล้ว',
}

//meet 遇见福利
export const meet = {
  title: 'สวัสดิการพบปะ',
  dec1: 'บัญชีแพลตฟอร์มที่ผู้เล่นลงทะเบียน จะเป็นวันที่ท่านพบปะกับแพลตฟอร์ม เมื่อเลเวล VIP ของท่านถึง V7 ขึ้นไป ระบบจะแจกสวัสดิการพบปะให้บัญชีของท่านโดยอัตโนมัติในวันนั้นของทุกปี สวัสดิการพบปะที่สอดคล้องกับ VIP ทั้งหมดมีดังนี้:',
  time: 'เวลาพบปะ:',
  table1: 'เลเวล VIP',
  table2: 'แพ็กสวัสดิการพบปะ',
  tableText: 'เติมเงิน {num}usd เพื่อรับคูปอง {coin}coin'
}

//recovery 误操作恢复
export const recovery = {
  title: 'ฟื้นฟูข้อผิดพลาด',
  dec1: 'เมื่อเลเวล VIP บัญชี MGOL ของผู้เล่นถึง V7ขึ้นไป จะได้สิทธิสมาชิกในการกู้คืนจากขโมย สิทธิกู้คืนจากขโมยที่สอดคล้องของเลเวล VIP มีดังนี้:',
  table1: 'เลเวล VIP',
  table2: 'จำนวนครั้งฟื้นฟู',
  tableText: 'ครั้ง/ครึ่งปี'
}


