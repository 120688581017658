
<template>
    <svg class="svg-icon" aria-hidden="true" :style="style">
        <use :xlink:href="iconName" />
    </svg>
</template>
<script>
export default {
    name: "svg-icon",
    props: {
        // 当前 svg 的文件名，如 arrow-left.svg，该值就应该是 arrow-left
        name: {
            type: String,
            required: true
        },
        // 颜色值
        color: {
            type: String,
            default: "#999999"
        }
    },
    computed: {
        iconName() {
            return `#icon-${this.name}`;
        },
        style() {
            return { "--color": this.color };
        }
    }
};
</script>
<style lang="scss" scoped>
.svg-icon {
  fill: currentColor;
  overflow: hidden;
  color: var(--color);
}
</style>
