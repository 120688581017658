import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import { Loading } from 'element-ui';
import axios from "./utils/axios";
import lodash from 'lodash'
import VueI18n from 'vue-i18n'
import GSignInButton from 'vue-google-signin-button'
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/fonts.css"
import "./assets/fonts/iconfont/iconfont.css"
// 全局组件
import SvgIcon from './components/svgIcon.vue'

Vue.config.productionTip = false;
Vue.prototype._ = lodash
Vue.prototype.$http = axios;
Vue.prototype.address = 'http://192.168.110.22'
Vue.prototype.$pathName = 'http://192.168.110.22'
Vue.prototype.$isMobile = /Mobile|Android|iphone|ipad|ipod|IOS|Macintosh|Surface Pro 7/i.test(navigator.userAgent);
Vue.prototype.$bus = new Vue();
Vue.prototype.$handlePadding = handlePadding;
Vue.use(ElementUI);
Vue.use(Loading.directive);
Vue.use(GSignInButton);
Vue.component('SvgIcon', SvgIcon)
const requireAll = requireContext => requireContext.keys().map(requireContext);
const req = require.context("./assets", true, /.svg$/);
requireAll(req);
Vue.use(VueI18n)

const languageList = ["VI", "ID", "EN", "TH", "ZH"];
const i18n = new VueI18n({
  locale: languageList.includes(localStorage.getItem('locale')) ? localStorage.getItem('locale') : 'EN',
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'ZH': require('./lang/zh'), // 中文语言包
    'EN': require('./lang/en'), // 英文语言包
    'ID': require('./lang/id'), // 印尼语言包
    // 'PH': require('./lang/en'), // 菲律宾语言包
    'TH': require('./lang/th'), // 泰国语言包
    'VI': require('./lang/vn'), // 越南语言包
  }
})

function handlePadding() {
  if (Vue.prototype.$isMobile) {
    let MutationObserver =
      window.MutationObserver ||
      window.WebKitMutationObserver ||
      window.MozMutationObserver;

    let observer = new MutationObserver((mutations) => {
      //console.log('mutations',mutations)
      mutations.forEach((item) => {
        if ("style" == item.attributeName) {
          document.body.style.padding = 0;
        }
      });
    });

    var body = document.body;

    var options = {
      attributes: true,
    };

    observer.observe(body, options);
  }
}




router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("token")
  let arr = ['/', '/login', '/login/autoLogin', '/purchase/index', '/atmResult', '/purchaseResult', '/main/deletion']
  if (arr.includes(to.path)) {
    if (to.path === '/login') {
      localStorage.removeItem("token");
      store.state.selectServe = false;
      store.state.showServe = false;
    }
    next()
  } else {
    if (token) {
      next()
    } else {
      next({ path: '/' })
    }

  }
})
let pathName = window.location.hostname;
if (pathName.indexOf("mocgame") > 0) {
  Vue.prototype.$pathName = 'mocgame'
} else if (pathName.indexOf("muugame") > 0) {
  Vue.prototype.$pathName = 'muugame'
} else {
  Vue.prototype.$pathName = 'mgol'
}
// let language = {
//   'pay-ph': 'en',
//   'pay-vn': 'vn',
//   'pay-id': 'id',
//   'pay-th': 'th',
// }
// let hostName = 'pay-ph.mgolgames.com'
// for (const i in language) {
//   if (hostName.includes(i)) {
//     i18n.locale = language[i];
//     localStorage.setItem("locale", language[i]);
//   }
// }

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
