import axios from "axios";
import route from '../router/index';

const instance = axios.create({
  // baseURL: "https://test-upac.muugame.com",
  baseURL: "/apis/",
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在请求发送之前做一些处理
    if (config.method == "post") {
      config.headers['Content-Type'] = config.headers['Content-Type'] + ";multipart/form-data"
    }
    if (localStorage.getItem("token") && route.currentRoute.path != "/purchase/index") {
      config.headers.Authorization = JSON.parse(localStorage.getItem("token"));
    } else {
      config.headers.Authorization = route.currentRoute.query.data
    }

    const locale = localStorage.getItem('locale') || 'EN';
    const country = JSON.parse(localStorage.getItem('service'))?.country || ''
    if (!config.url.includes("/purchase/")) {
      if (config.params) {
        // 如果已存在，则将 counter 参数添加到 params 对象中
        config.params.language = locale;
      } else {
        // 如果不存在，则创建 params 对象，并设置 counter 参数为 1
        config.params = { language: locale };
      }
      if (country) {
        if (config.params) {
          // 如果已存在，则将 counter 参数添加到 params 对象中
          config.params.country = country;
        } else {
          // 如果不存在，则创建 params 对象，并设置 counter 参数为 1
          config.params = { language: locale, country: country };
        }
      }
    }
    

    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 对响应数据做一些处理
    if (response.data.code === 206) {
      route.push("/");
    }
    return response;
  },
  (error) => {
    // 对响应错误做些什么
    return Promise.reject(error);
  }
);

export default instance;
